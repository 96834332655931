export default `
  @font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Regular-webfont.woff') format('woff'),
        url('/fonts/Roboto-Regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Dejavu';
    src: url('/fonts/DejaVuSans.woff') format('woff'),
        url('/fonts/DejaVuSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* @font-face {
    font-family: 'CustomFont';
    font-weight: 300;
    font-style: normal;
    font-display: block;
    src: url('/fonts/customFont/CustomFont-ExtraLight.woff2') format('woff2'),
      url('/fonts/customFont/CustomFont-ExtraLight.woff') format('woff');
  }

  @font-face {
    font-family: 'CustomFont';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url('/fonts/customFont/CustomFont-Light.woff2') format('woff2'),
      url('/fonts/customFont/CustomFont-Light.woff') format('woff');
  }

  @font-face {
    font-family: 'CustomFont';
    font-weight: 500;
    font-style: normal;
    font-display: block;
    src: url('/fonts/customFont/CustomFont-Regular.woff2') format('woff2'),
      url('/fonts/customFont/CustomFont-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'CustomFont';
    font-weight: 600;
    font-style: normal;
    font-display: block;
    src: url('/fonts/customFont/CustomFont-Medium.woff2') format('woff2'),
      url('/fonts/customFont/CustomFont-Medium.woff') format('woff');
  }

  @font-face {
    font-family: 'CustomFont';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url('/fonts/customFont/CustomFont-SemiBold.woff2') format('woff2'),
      url('/fonts/customFont/CustomFont-SemiBold.woff') format('woff');
  }

  @font-face {
    font-family: 'CustomFont';
    font-weight: 800;
    font-style: normal;
    font-display: block;
    src: url('/fonts/customFont/CustomFont-Bold.woff2') format('woff2'),
      url('/fonts/customFont/CustomFont-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'CustomFont';
    font-weight: 900;
    font-style: normal;
    font-display: block;
    src: url('/fonts/customFont/CustomFont-ExtraBold.woff2') format('woff2'),
      url('/fonts/customFont/CustomFont-ExtraBold.woff') format('woff');
  } */
`;
