import isValidProp from '@emotion/is-prop-valid';
import * as Sentry from '@sentry/react';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import type { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { setupStoreDevTools, useCopyStore } from 'store';
import Head from 'u9/components/Head/Head';
import Version from 'u9/components/versioning/Version/Version';
import VersionScreen from 'u9/components/versioning/VersionScreen/VersionScreen';
import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/globalStyles';
import theme from 'utils/styles/theme';

import 'intersection-observer';

if (process.env.ENV !== 'local' && !!process.env.SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: process.env.SENTRY_DSN,
  });
}

const App = ({ Component, pageProps, router }: AppProps) => {
  const [isMounted, setMounted] = useState(false);
  const { copy, setCopy } = useCopyStore();

  useEffect(() => {
    if (process.env.IS_DEBUG) setupStoreDevTools();
    if (pageProps.initialCopy) setCopy(pageProps.initialCopy);

    setMounted(true);

    // Baidu Analytics
    const meta = document.createElement('meta');
    meta.name = 'referrer';
    meta.content = 'unsafe-url';
    document.head.appendChild(meta);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    //@ts-ignore
    const _hmt = _hmt || [];
    (function () {
      const hm = document.createElement('script');
      hm.src = 'https://hm.baidu.com/hm.js?95db08cbaaa9181df33cb8574ff05b3b';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(hm, s);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider theme={{ ...theme, locale: router.locale }}>
        <MotionConfig isValidProp={isValidProp}>
          <GlobalStyles />
          <style dangerouslySetInnerHTML={{ __html: customFonts }} />

          <Head {...(pageProps.initialCopy || copy).head} />

          <AnimatePresence exitBeforeEnter={true} initial={false}>
            <Component key={router.route} router={router} {...pageProps} />
          </AnimatePresence>

          {process.env.IS_DEBUG && <Version />}
          {isMounted
            ? process.env.IS_DEBUG &&
              window.location.hash === '#version' && <VersionScreen />
            : null}
        </MotionConfig>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
